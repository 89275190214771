export const PERMISSIONS = {
  ADMIN: {
    ADD_LOGENTRY: 'admin.add_logentry',
    CHANGE_LOGENTRY: 'admin.change_logentry',
    DELETE_LOGENTRY: 'admin.delete_logentry',
    VIEW_LOGENTRY: 'admin.view_logentry'
  },
  AUTH: {
    ADD_GROUP: 'auth.add_group',
    ADD_PERMISSION: 'auth.add_permission',
    CHANGE_GROUP: 'auth.change_group',
    CHANGE_PERMISSION: 'auth.change_permission',
    DELETE_GROUP: 'auth.delete_group',
    DELETE_PERMISSION: 'auth.delete_permission',
    VIEW_GROUP: 'auth.view_group',
    VIEW_PERMISSION: 'auth.view_permission'
  },
  AUTHTOKEN: {
    ADD_TOKEN: 'authtoken.add_token',
    ADD_TOKENPROXY: 'authtoken.add_tokenproxy',
    CHANGE_TOKEN: 'authtoken.change_token',
    CHANGE_TOKENPROXY: 'authtoken.change_tokenproxy',
    DELETE_TOKEN: 'authtoken.delete_token',
    DELETE_TOKENPROXY: 'authtoken.delete_tokenproxy',
    VIEW_TOKEN: 'authtoken.view_token',
    VIEW_TOKENPROXY: 'authtoken.view_tokenproxy'
  },
  CONTENTTYPES: {
    ADD_CONTENTTYPE: 'contenttypes.add_contenttype',
    CHANGE_CONTENTTYPE: 'contenttypes.change_contenttype',
    DELETE_CONTENTTYPE: 'contenttypes.delete_contenttype',
    VIEW_CONTENTTYPE: 'contenttypes.view_contenttype'
  },
  SESSIONS: {
    ADD_SESSION: 'sessions.add_session',
    CHANGE_SESSION: 'sessions.change_session',
    DELETE_SESSION: 'sessions.delete_session',
    VIEW_SESSION: 'sessions.view_session'
  },
  SPORTMONKS: {
    ADD_SPORTMONKCITIE: 'sportmonks.add_sportmonkcitie',
    ADD_SPORTMONKCONTINENT: 'sportmonks.add_sportmonkcontinent',
    ADD_SPORTMONKCORETYPE: 'sportmonks.add_sportmonkcoretype',
    ADD_SPORTMONKCOUNTRY: 'sportmonks.add_sportmonkcountry',
    ADD_SPORTMONKREGION: 'sportmonks.add_sportmonkregion',
    CHANGE_SPORTMONKCITIE: 'sportmonks.change_sportmonkcitie',
    CHANGE_SPORTMONKCONTINENT: 'sportmonks.change_sportmonkcontinent',
    CHANGE_SPORTMONKCORETYPE: 'sportmonks.change_sportmonkcoretype',
    CHANGE_SPORTMONKCOUNTRY: 'sportmonks.change_sportmonkcountry',
    CHANGE_SPORTMONKREGION: 'sportmonks.change_sportmonkregion',
    DELETE_SPORTMONKCITIE: 'sportmonks.delete_sportmonkcitie',
    DELETE_SPORTMONKCONTINENT: 'sportmonks.delete_sportmonkcontinent',
    DELETE_SPORTMONKCORETYPE: 'sportmonks.delete_sportmonkcoretype',
    DELETE_SPORTMONKCOUNTRY: 'sportmonks.delete_sportmonkcountry',
    DELETE_SPORTMONKREGION: 'sportmonks.delete_sportmonkregion',
    VIEW_SPORTMONKCITIE: 'sportmonks.view_sportmonkcitie',
    VIEW_SPORTMONKCONTINENT: 'sportmonks.view_sportmonkcontinent',
    VIEW_SPORTMONKCORETYPE: 'sportmonks.view_sportmonkcoretype',
    VIEW_SPORTMONKCOUNTRY: 'sportmonks.view_sportmonkcountry',
    VIEW_SPORTMONKREGION: 'sportmonks.view_sportmonkregion'
  },
  USERS: {
    ADD_USER: 'users.add_user',
    ADD_USERROLE: 'users.add_userrole',
    CHANGE_USER: 'users.change_user',
    CHANGE_USERROLE: 'users.change_userrole',
    DELETE_USER: 'users.delete_user',
    DELETE_USERROLE: 'users.delete_userrole',
    VIEW_USER: 'users.view_user',
    VIEW_USERROLE: 'users.view_userrole'
  }
};

export const enum ROLES {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER'
}

export const enum ODDS_TYPE {
  ASIAN_HANDICAP = 'ASIAN_HANDICAP',
  OVER_UNDER = 'OVER_UNDER',
  ODD_EVEN = 'ODD_EVEN'
}

export const enum ODDS_SIDE {
  HOME = 'HOME',
  AWAY = 'AWAY',
  OVER = 'OVER',
  UNDER = 'UNDER',
  ODD = 'ODD',
  EVEN = 'EVEN'
}

export const enum STATUS_ENUM {
  DRAFT = 'DRAFT',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export const STATUS_OPTIONS = {
  [STATUS_ENUM.DRAFT]: 'Draft',
  [STATUS_ENUM.PUBLIC]: 'Public',
  [STATUS_ENUM.PRIVATE]: 'Private'
};

export const enum POSITION_ZONE {
  ATTACKER = 'ATTACKER',
  MIDFIELDER = 'MIDFIELDER',
  DEFENDER = 'DEFENDER',
  GOALKEEPER = 'GOALKEEPER'
}

export const enum SEARCHPARAMS_ENUM {
  IS_VERIFY_TRANSLATION = 'is_verify_translation',
  HAS_ODDS = 'has_odds',
  HAS_ARTICLE = 'has_article'
}
