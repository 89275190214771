import { IconDeviceDesktop } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const datamb = {
  id: 'datamb',
  title: 'DataMB',
  type: 'group',
  children: [
    {
      id: 'datamb-monitor',
      title: 'Monitor',
      type: 'item',
      url: routeLinks.datamb.tasks.monitors.list,
      activeUrls: [routeLinks.datamb.tasks.monitors.list, routeLinks.datamb.tasks.monitors.view],
      icon: IconDeviceDesktop,
      breadcrumbs: true
    }
  ]
};

export default datamb;
