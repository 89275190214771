import { IconDeviceDesktop, IconNetwork } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const whoscored = {
  id: 'whoscored',
  title: 'WhoScored',
  type: 'group',
  children: [
    {
      id: 'whoscored-monitor',
      title: 'Monitor',
      type: 'item',
      url: routeLinks.whoscored.tasks.monitors.list,
      activeUrls: [routeLinks.whoscored.tasks.monitors.list, routeLinks.whoscored.tasks.monitors.view],
      icon: IconDeviceDesktop,
      breadcrumbs: true
    },
    {
      id: 'whoscored-proxy',
      title: 'Proxy',
      type: 'item',
      url: routeLinks.whoscored.workers.proxies.list,
      activeUrls: [
        routeLinks.whoscored.workers.proxies.list,
        routeLinks.whoscored.workers.proxies.view,
        routeLinks.whoscored.workers.proxies.edit
      ],
      icon: IconNetwork,
      breadcrumbs: true
    }
  ]
};

export default whoscored;
