import { IconDeviceDesktop } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const fminside = {
  id: 'fminside',
  title: 'FMInside',
  type: 'group',
  children: [
    {
      id: 'fminside-monitor',
      title: 'Monitor',
      type: 'item',
      url: routeLinks.fminside.tasks.monitors.list,
      activeUrls: [routeLinks.fminside.tasks.monitors.list, routeLinks.fminside.tasks.monitors.view],
      icon: IconDeviceDesktop,
      breadcrumbs: true
    }
  ]
};

export default fminside;
