import bookmakers from './bookmaker';
import dashboard from './dashboard';
import datamb from './datamb';
import fminside from './fminside';
import football from './football';
import general from './general';
import matches from './matches';
import settings from './settings';
import users from './users';
import whoscored from './whoscored';

interface MenuItemChildren {
  id: string;
  title: string;
  type: string;
  url?: string;
  icon?: any;
  breadcrumbs?: boolean;
  target?: boolean;
  children?: MenuItemChildren[];
}
export interface MenuItem {
  id: string;
  title: string;
  type: string;
  allowPermissions?: string[];
  children: MenuItemChildren[];
}

interface MenuItems {
  items: MenuItem[];
}

const menuItems: MenuItems = {
  items: [dashboard, general, football, matches, bookmakers, settings, whoscored, datamb, fminside, users]
};

export default menuItems;
